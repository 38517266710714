<template>
  <div>
    <BaseLoading style="min-height: 400px;height: 90vh;" v-if="loading"></BaseLoading>

    <div v-else class="text-left">
      <BaseHeader :title="'My Discounts'"></BaseHeader>

      <div class="card mt-2 border-0 bg-white shadow">
        <div class="card-body text-right d-flex" style>
          <!-- <form>
                <div class="input-group">
                  <input type="text" class="form-control" placeholder="Start typing to search">
                  <span class="input-group-append">
                    <button class="btn btn-success" type="search">Search</button>
                  </span>
                </div>
          </form>-->
          <h3>My Discounts</h3>
        </div>

        <div class="alert alert-success mx-4">
          <span v-html="discounts.message || ''"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "Wallet",
  computed: {
    ...mapState("payment", ["loading", "discounts"]),
  },
  async mounted() {
    await this._getDiscounts();
  },
  methods: {
    ...mapActions("payment", ["_getDiscounts"]),
  },
};
</script>
